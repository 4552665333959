@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#__next,
#layout {
  height: 100%;
}

.checkbox,
.textfield,
select {
  @apply ring-surface-variant ring-1 ring-inset;
}

.textfield:focus-within,
select:focus-visible,
button:focus-visible,
a:focus-visible {
  outline: none;
  @apply ring-primary70 ring-1 ring-inset;
}

input:focus-visible,
textarea:focus-visible {
  outline: none;
}

/* https://stackoverflow.com/a/22306944/13151903 */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type='color']::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type='color']::-webkit-color-swatch {
  @apply border-outline-variant;
}

.scroll {
  overflow: overlay;
}
.scroll::-webkit-scrollbar {
  width: 10px;
}
.scroll:hover::-webkit-scrollbar-thumb {
  @apply bg-on-background/10 hover:bg-on-background/20 active:bg-on-background/30;
}
.scroll-parent {
  @apply flex flex-col overflow-hidden;
}

.scroll-h {
  @apply overflow-x-auto;
}
.scroll-h::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.match-highlight {
  @apply bg-yellow-500/30;
  color: unset;
}

p {
  @apply py-2;
}

/* https://daisyui.com/components/divider */
.divider {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  height: 1rem;
  white-space: nowrap;
  @apply my-2 gap-2;
}
.divider:before,
.divider:after {
  content: '';
  flex-grow: 1;
  width: 100%;
  @apply bg-surface-variant h-px;
}

.progress-bit {
  animation-name: progress;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  transform: translate3d(0px, 0px, 0px);
  animation-timing-function: linear;
}

@keyframes progress {
  from {
    transform: translateX(0%) scaleX(1);
  }
  50% {
    transform: translateX(1000%) scaleX(3);
  }
  to {
    transform: translateX(2000%) scaleX(1);
  }
}

.list-row:hover .action {
  display: block;
}
